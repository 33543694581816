import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GeneralButton from "../GeneralButton/GenerallButton";
import ActiveRangeIcon from "../../images/menuIcons/dai_icon.svg";
import { Modal } from 'react-bootstrap';
import { convertToTime, fonrmatEather } from "../../components/blockchain/utils";
// import { SubmitBondDeposit } from '../../components/blockchain/commonFunction'
const TokenTable = ({ 
  marketDetails,
  openModal,
  setOpenModal,
}) => {

  const [maxPrice, setMaxPrice] = useState();
  const [liveId, setLiveId] = useState();
  const [userValue, setUserValue] = useState();

  const handleSubmit = (item) => {
    setMaxPrice(item.marketPrice)
    setLiveId(item.MarketId)
    setOpenModal(true)
  }

  // const handleSubmitUsdao = async () =>{
  // await SubmitBondDeposit(userValue, maxPrice, liveId)
  // }

  return (
    <TableContent>
      <Header>
        <Token>
          <FieldName>Token</FieldName>
        </Token>
        <Price>
          <FieldName>Price</FieldName>
        </Price>
        <Discount>
          <FieldName>Discount</FieldName>
        </Discount>
        <MaxPayout>
          <FieldName>Max Payout</FieldName>
        </MaxPayout>
        <Duration>
          <FieldName>Duration</FieldName>
        </Duration>
        <Button>{/* <FieldName>Duration</FieldName> */}</Button>
      </Header>
      <Modal
        style={{
          marginTop: '45vh',
          color: '#32373e',
        }}
        size="md"
        show={openModal}
        onHide={() => setOpenModal(false)}
      >
        <Modal.Header
          style={{
            backgroundColor: 'rgb(50, 55, 62)'
          }}
          closeButton>
          <Modal.Title style={{ color: '#ffffff' }}>Input USDAO</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          backgroundColor: 'rgb(50, 55, 62)',
          padding: '30px'
        }}>
          <ModalDislpay>
            <ModalTitle>
              <span style={{
                padding: '1px 24px 2px 1px',
              }}>
                <label style={{
                  color: '#2be79a'
                }}>
                  USDAO :
                </label>
              </span>
              <input value={userValue}
                onChange={(e) => setUserValue(e.target.value)}
                style={{ borderColor: '#2be79a' }}
                type="number"
                title="Enter" />

            </ModalTitle>
            <ModalButton
            //  onClick={() => handleSubmitUsdao()}
             >
            <Button>
              <GeneralButton
                bgColor="#32373e"
                labelColor="white"
                label="Submit"
                border="1px solid white"
              >
              </GeneralButton>

            </Button>
            </ModalButton>
          </ModalDislpay>
        </Modal.Body>
      </Modal>
      {marketDetails === undefined || marketDetails.length > 0 && (
        <>
          {marketDetails.map((item, index) => {
            return (
              <TableRow key={index}>
                <Token>
                  <TokenName>
                    <Icon src={ActiveRangeIcon} />
                    <TokenTextContainer>
                      <TokenTitle>DAI</TokenTitle>
                      <SubTitle>Get Asset</SubTitle>
                    </TokenTextContainer>
                  </TokenName>
                </Token>
                <Price>
                  <FieldName>{fonrmatEather(item.Price)} DAI</FieldName>
                </Price>
                <Discount>
                  <DiscountContent>99.9%</DiscountContent>
                </Discount>
                <MaxPayout>
                  <MaxPayoutContent>
                    <PayoutAmount>{fonrmatEather(item.MaxPay)} SDAO</PayoutAmount>
                  </MaxPayoutContent>
                </MaxPayout>
                <Duration>
                  <FieldName>{convertToTime(item.Duration)} min</FieldName>
                </Duration>
                <Button onClick={() => handleSubmit(item)}>
                  <GeneralButton
                    bgColor="#32373e"
                    labelColor="white"
                    label="Bond for SDAO"
                    border="1px solid white"
                  />
                </Button>
              </TableRow>
            );
          })}
        </>
      )}
      {marketDetails === undefined || marketDetails.length <= 0 && (
        <div>
          <div colSpan={12}>
            <NoData >
              No Data Found
            </NoData>
          </div>
        </div>
      )}

    </TableContent>
  );
};

export default TokenTable;

const NoData = styled.div`
align-items: center;
color: white;
margin-right: 40%;
margin-left: 40%;
`

const SubTitle = styled.div`
  font-size: 12px;
  color: white;
`;
const TokenTitle = styled.div`
  font-size: 16px;
  color: white;
  font-weight: 500;
  margin-bottom: 3px;
`;
const TokenTextContainer = styled.div`
  // display: flex;
  margin-left: 7px;
`;
const TokenName = styled.div`
  display: flex;
  align-items: center;
`;
const Icon = styled.img`
  width: 30px;
  height: 30px;
`;
const MaxPayoutContent = styled.div``;
const PayoutAmount = styled.div`
  font-size: 14px;
  color: white;
`;
const DiscountContent = styled.div`
  font-size: 12px;
  height: 28px;
  border-radius: 14px;
  background-color: #2be79a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
`;
const Button = styled.div`
  flex: 1;
`;
const Token = styled.div`
  flex: 1;
`;
const Price = styled.div`
  flex: 1;
`;
const Discount = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
`;
const MaxPayout = styled.div`
  flex: 1;
`;
const Duration = styled.div`
  flex: 1;
`;
const FieldName = styled.div`
  flex: 1;
  color: white;
  font-size: 14px;
  font-weight: 600;
`;

const Header = styled.div`
  display: flex;
  padding: 10px 0;
  margin-bottom: 15px;
`;
const TableRow = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
`;
const TableContent = styled.div`
  background-color: #32373e;
  // width: 100%;
  border-radius: 5px;
  margin-top: 50px;
  padding: 20px 20px;
`;

const ModalDislpay = styled.div`
display: flex;
`

const ModalTitle = styled.div`
flex : 2;
padding: 6px;
`
const ModalButton = styled.div`
flex : 1;
`