import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import styled from "styled-components";
import { Title } from "../viewStyles";
import StatInfo from "../../components/StatInfo";
import GeneralButton from "../../components/GeneralButton";
import { BiWallet } from "react-icons/bi";
import Selector from "../../components/Selector";
import DashboardChart from "../../components/DashboardChart";
import Holdings from "./Holdings";
import { getLiquidBacking, getTotalSupply, getIndexValue, circulatingSupply, getOhmTotalSupply } from '../../components/blockchain/commonFunction'
import {  setExtendNav } from '../../redux/action'
import { useNavigate} from "react-router-dom";



const periodItems = [
  { label: "7d" },
  { label: "30d" },
  { label: "90d" },
  ,
  { label: "Max" },
];
const tokenItems = [{ label: "SDAO" }, { label: "gSDAO" }];

const Dashboard = ({ }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state => state));
  const [marketCap, setMarketCap] = useState('0.00');
  const [index, setIndex] = useState('0.00');
  const [liquidBacking, setLiquidBacking] = useState('0.00');
  const [circulating, setCirculating] = useState('0.00');
  const [totalOhm, setTotalOhm] = useState('0.00')
  const navigate = useNavigate()


  useEffect(() => {
      (async () => {
        try{
          const totalSupply = await getTotalSupply(setMarketCap);
          setMarketCap(totalSupply)
          const indexValue = await getIndexValue(setIndex)
          setIndex(indexValue)
          const balance = await getLiquidBacking();
          setLiquidBacking(balance)
          const cbalance = await circulatingSupply();
          setCirculating(cbalance)
          const ohmTotal = await getOhmTotalSupply()
          setTotalOhm(ohmTotal)
        }catch(err){
          console.log(err)
        }
  
      })()
    dispatch(setExtendNav(false))
    return () => {
      
    }
    
  }, [marketCap, index])

  useEffect(() => {
    if(selector.appReducer.account_id === ""){
      navigate('/wrap')
  }
  return () => {
    
  }
  }, [])

  return (
    <>
    <Container>
      <Content>
        <TopLine>
          <Title>Dashboard</Title>
          <StakeButtonContainer>
            <GeneralButton
              bgColor="green"
              labelColor="white"
              label={selector.appReducer.account_id === "" || selector.appReducer.account_id === undefined ? 'Connect Wallet' : 'Connected'}
              icon={<BiWallet size={20} color={"white"} />}
            />
          </StakeButtonContainer>
        </TopLine>
        <Panel>
          <InfoLine>
            <StatInfo value={marketCap} label="SDAO Market Cap" />
            <StatInfo value={1} label="SDAO Price" />
            <StatInfo value={Number(index) * 1} label="gSDAO Price" />
          </InfoLine>
          <InfoLine>
            <StatInfo
              value={circulating}
              total={totalOhm}
              label="SDAO Circulating Supply / Total"
            />
            <StatInfo value={liquidBacking} label="Liquid Backing per SDAO" />
            <StatInfo value={index} label="Current Index" />
          </InfoLine>
        </Panel>
        <SelectContainer>
          <Selector menus={periodItems} />
          <Selector menus={tokenItems} />
        </SelectContainer>
        <ChartContainer>
          <DashboardChart title="SDAO Backing" value={10.97} />
        </ChartContainer>
        <ChartContainer>
          <DashboardChart
            title="Treasury Liquid Backing"
            bottomComponent={<Holdings />}
            value={207491137}
          />
        </ChartContainer>
        <ChartContainer>
          <DashboardChart title="Protocol-Owned Liquidity" value={73420945} />
        </ChartContainer>
        <ChartContainer>
          <DashboardChart title="SDAO Supply" />
        </ChartContainer>
      </Content>
    </Container>
    </>
  );
};

export default Dashboard;

const ChartContainer = styled.div`
  margin-top: 50px;
  background-color: #32373e;
  padding: 20px;
  z-index:2
`;
const SelectContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;
const InfoLine = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 568px) {
    flex-direction: column;
  }
`;

const StakeButtonContainer = styled.div`
  width: 140px;
`;

const TopLine = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 60px;
  justify-content: space-between;
`;
const Container = styled.div`
  background-color: #1e1e1e;
  flex: 1;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
`;
const Content = styled.div`
  margin-top: 50px;
  display: flex;
  width: 90%;
  flex-direction: column;
`;

const Panel = styled.div`
  background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
    linear-gradient(to right, #beffba, #ffffff) border-box;
  border: 3px solid transparent;
  border-radius: 10px;
  padding: 20px 20px;
  flex-direction: column;
`;
