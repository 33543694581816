const { ethers } = require("ethers");
export const provider = new ethers.providers.JsonRpcProvider(
  "https://goerli.infura.io/v3/0f1d34179ba34aedad509904d800610f"
);

export const contractDetails = {
  ohm: {
    name: 'ohm',
    address: "0x76EFad6d55F91C70666e0e97A34A3ce75a402551",
    abi: [
      {
        inputs: [{ internalType: "address", name: "_authority", type: "address" }],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "contract IPanaAuthority",
            name: "authority",
            type: "address",
          },
        ],
        name: "AuthorityUpdated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "address", name: "from", type: "address" },
          { indexed: true, internalType: "address", name: "to", type: "address" },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        inputs: [],
        name: "DOMAIN_SEPARATOR",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "owner", type: "address" },
          { internalType: "address", name: "spender", type: "address" },
        ],
        name: "allowance",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "approve",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "authority",
        outputs: [
          { internalType: "contract IPanaAuthority", name: "", type: "address" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
        name: "burn",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "account_", type: "address" },
          { internalType: "uint256", name: "amount_", type: "uint256" },
        ],
        name: "burnFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "decimals",
        outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "subtractedValue", type: "uint256" },
        ],
        name: "decreaseAllowance",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "addedValue", type: "uint256" },
        ],
        name: "increaseAllowance",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "account_", type: "address" },
          { internalType: "uint256", name: "amount_", type: "uint256" },
        ],
        name: "mint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "name",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "owner", type: "address" }],
        name: "nonces",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "owner", type: "address" },
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "value", type: "uint256" },
          { internalType: "uint256", name: "deadline", type: "uint256" },
          { internalType: "uint8", name: "v", type: "uint8" },
          { internalType: "bytes32", name: "r", type: "bytes32" },
          { internalType: "bytes32", name: "s", type: "bytes32" },
        ],
        name: "permit",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "contract IPanaAuthority",
            name: "_newAuthority",
            type: "address",
          },
        ],
        name: "setAuthority",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "symbol",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "totalSupply",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "recipient", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "transfer",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "sender", type: "address" },
          { internalType: "address", name: "recipient", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
    ]
  },
  bondDepository: {
    address: "0x336A0447C6976129Cd5135276F6d39EE15297550",
    abi: [
      {
        inputs: [
          {
            internalType: "contract IOlympusAuthority",
            name: "_authority",
            type: "address",
          },
          { internalType: "contract IERC20", name: "_ohm", type: "address" },
          { internalType: "contract IgOHM", name: "_gohm", type: "address" },
          {
            internalType: "contract IStaking",
            name: "_staking",
            type: "address",
          },
          {
            internalType: "contract ITreasury",
            name: "_treasury",
            type: "address",
          },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "contract IOlympusAuthority",
            name: "authority",
            type: "address",
          },
        ],
        name: "AuthorityUpdated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "uint256", name: "id", type: "uint256" },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
        ],
        name: "Bond",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "uint256", name: "id", type: "uint256" },
        ],
        name: "CloseMarket",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "uint256", name: "id", type: "uint256" },
          {
            indexed: true,
            internalType: "address",
            name: "baseToken",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "quoteToken",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "initialPrice",
            type: "uint256",
          },
        ],
        name: "CreateMarket",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          { indexed: true, internalType: "uint256", name: "id", type: "uint256" },
          {
            indexed: false,
            internalType: "uint64",
            name: "oldControlVariable",
            type: "uint64",
          },
          {
            indexed: false,
            internalType: "uint64",
            name: "newControlVariable",
            type: "uint64",
          },
        ],
        name: "Tuned",
        type: "event",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "adjustments",
        outputs: [
          { internalType: "uint64", name: "change", type: "uint64" },
          { internalType: "uint48", name: "lastAdjustment", type: "uint48" },
          { internalType: "uint48", name: "timeToAdjusted", type: "uint48" },
          { internalType: "bool", name: "active", type: "bool" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "authority",
        outputs: [
          {
            internalType: "contract IOlympusAuthority",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
        name: "close",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "contract IERC20",
            name: "_quoteToken",
            type: "address",
          },
          { internalType: "uint256[3]", name: "_market", type: "uint256[3]" },
          { internalType: "bool[2]", name: "_booleans", type: "bool[2]" },
          { internalType: "uint256[2]", name: "_terms", type: "uint256[2]" },
          { internalType: "uint32[2]", name: "_intervals", type: "uint32[2]" },
        ],
        name: "create",
        outputs: [{ internalType: "uint256", name: "id_", type: "uint256" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
        name: "currentControlVariable",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
        name: "currentDebt",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "daoReward",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
        name: "debtDecay",
        outputs: [{ internalType: "uint64", name: "", type: "uint64" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
        name: "debtRatio",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_id", type: "uint256" },
          { internalType: "uint256", name: "_amount", type: "uint256" },
          { internalType: "uint256", name: "_maxPrice", type: "uint256" },
          { internalType: "address", name: "_user", type: "address" },
          { internalType: "address", name: "_referral", type: "address" },
        ],
        name: "deposit",
        outputs: [
          { internalType: "uint256", name: "payout_", type: "uint256" },
          { internalType: "uint256", name: "expiry_", type: "uint256" },
          { internalType: "uint256", name: "index_", type: "uint256" },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "getReward",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "_user", type: "address" }],
        name: "indexesFor",
        outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
        name: "isLive",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "liveMarkets",
        outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "_token", type: "address" }],
        name: "liveMarketsFor",
        outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_id", type: "uint256" }],
        name: "marketPrice",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "markets",
        outputs: [
          { internalType: "uint256", name: "capacity", type: "uint256" },
          {
            internalType: "contract IERC20",
            name: "quoteToken",
            type: "address",
          },
          { internalType: "bool", name: "capacityInQuote", type: "bool" },
          { internalType: "uint64", name: "totalDebt", type: "uint64" },
          { internalType: "uint64", name: "maxPayout", type: "uint64" },
          { internalType: "uint64", name: "sold", type: "uint64" },
          { internalType: "uint256", name: "purchased", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "", type: "address" },
          { internalType: "uint256", name: "", type: "uint256" },
        ],
        name: "marketsForQuote",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "metadata",
        outputs: [
          { internalType: "uint48", name: "lastTune", type: "uint48" },
          { internalType: "uint48", name: "lastDecay", type: "uint48" },
          { internalType: "uint48", name: "length", type: "uint48" },
          { internalType: "uint48", name: "depositInterval", type: "uint48" },
          { internalType: "uint48", name: "tuneInterval", type: "uint48" },
          { internalType: "uint8", name: "quoteDecimals", type: "uint8" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "", type: "address" },
          { internalType: "uint256", name: "", type: "uint256" },
        ],
        name: "notes",
        outputs: [
          { internalType: "uint256", name: "payout", type: "uint256" },
          { internalType: "uint48", name: "created", type: "uint48" },
          { internalType: "uint48", name: "matured", type: "uint48" },
          { internalType: "uint48", name: "redeemed", type: "uint48" },
          { internalType: "uint48", name: "marketID", type: "uint48" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_amount", type: "uint256" },
          { internalType: "uint256", name: "_id", type: "uint256" },
        ],
        name: "payoutFor",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_user", type: "address" },
          { internalType: "uint256", name: "_index", type: "uint256" },
        ],
        name: "pendingFor",
        outputs: [
          { internalType: "uint256", name: "payout_", type: "uint256" },
          { internalType: "bool", name: "matured_", type: "bool" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_from", type: "address" },
          { internalType: "uint256", name: "_index", type: "uint256" },
        ],
        name: "pullNote",
        outputs: [
          { internalType: "uint256", name: "newIndex_", type: "uint256" },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_to", type: "address" },
          { internalType: "uint256", name: "_index", type: "uint256" },
        ],
        name: "pushNote",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_user", type: "address" },
          { internalType: "uint256[]", name: "_indexes", type: "uint256[]" },
          { internalType: "bool", name: "_sendgOHM", type: "bool" },
        ],
        name: "redeem",
        outputs: [{ internalType: "uint256", name: "payout_", type: "uint256" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "_user", type: "address" },
          { internalType: "bool", name: "_sendgOHM", type: "bool" },
        ],
        name: "redeemAll",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "refReward",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "rewards",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "contract IOlympusAuthority",
            name: "_newAuthority",
            type: "address",
          },
        ],
        name: "setAuthority",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint256", name: "_toFrontEnd", type: "uint256" },
          { internalType: "uint256", name: "_toDAO", type: "uint256" },
        ],
        name: "setRewards",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        name: "terms",
        outputs: [
          { internalType: "bool", name: "fixedTerm", type: "bool" },
          { internalType: "uint64", name: "controlVariable", type: "uint64" },
          { internalType: "uint48", name: "vesting", type: "uint48" },
          { internalType: "uint48", name: "conclusion", type: "uint48" },
          { internalType: "uint64", name: "maxDebt", type: "uint64" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "updateTreasury",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "_operator", type: "address" }],
        name: "whitelist",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "whitelisted",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
    ]
  },
  daiContract: {
    address: "0x2B14CA14DD433788BC46e824a6EC693b2F5de891",
    abi: [
      {
        inputs: [{ internalType: "uint256", name: "chainId_", type: "uint256" }],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "src",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "guy",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "wad",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: true,
        inputs: [
          { indexed: true, internalType: "bytes4", name: "sig", type: "bytes4" },
          {
            indexed: true,
            internalType: "address",
            name: "usr",
            type: "address",
          },
          {
            indexed: true,
            internalType: "bytes32",
            name: "arg1",
            type: "bytes32",
          },
          {
            indexed: true,
            internalType: "bytes32",
            name: "arg2",
            type: "bytes32",
          },
          { indexed: false, internalType: "bytes", name: "data", type: "bytes" },
        ],
        name: "LogNote",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "src",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "dst",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "wad",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        inputs: [],
        name: "DOMAIN_SEPARATOR",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "PERMIT_TYPEHASH",
        outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "usr", type: "address" }],
        name: "addAuth",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_limit", type: "uint256" }],
        name: "adjustDailyDAILimit",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "account_", type: "address" },
          { internalType: "address", name: "sender_", type: "address" },
        ],
        name: "allowance",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "usr_", type: "address" },
          { internalType: "uint256", name: "wad_", type: "uint256" },
        ],
        name: "approve",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "usr", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "burn",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "daiMintedToday",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "dailyDAILimit",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "decimals",
        outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "guy", type: "address" }],
        name: "deny",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "lastMintRestart",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "usr", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "mint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "src", type: "address" },
          { internalType: "address", name: "dst", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "move",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "name",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "nonces",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "holder", type: "address" },
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "nonce", type: "uint256" },
          { internalType: "uint256", name: "expiry", type: "uint256" },
          { internalType: "bool", name: "allowed", type: "bool" },
          { internalType: "uint8", name: "v", type: "uint8" },
          { internalType: "bytes32", name: "r", type: "bytes32" },
          { internalType: "bytes32", name: "s", type: "bytes32" },
        ],
        name: "permit",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "usr", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "pull",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "usr", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "push",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "guy", type: "address" }],
        name: "rely",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "symbol",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "totalSupply",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "dst", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "transfer",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "src", type: "address" },
          { internalType: "address", name: "dst", type: "address" },
          { internalType: "uint256", name: "wad", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "version",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "address", name: "", type: "address" }],
        name: "wards",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
    ]
  },
  stakeContract: {
    address: '0x0b12fD3F33bBF2452b5A69C36330F2fC341353d4',
    abi: [
      {
        "inputs":
          [
            {
              "internalType": "address",
              "name": "_ohm",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "_sOHM",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "_gOHM",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "_epochLength",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "_firstEpochNumber",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "_firstEpochTime",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "_authority",
              "type": "address"
            }
          ],
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "anonymous": false,
        "inputs":
          [
            {
              "indexed": true,
              "internalType": "contract IOlympusAuthority",
              "name": "authority",
              "type": "address"
            }
          ],
        "name": "AuthorityUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs":
          [
            {
              "indexed": false,
              "internalType": "address",
              "name": "distributor",
              "type": "address"
            }
          ],
        "name": "DistributorSet",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs":
          [
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "warmup",
              "type": "uint256"
            }
          ],
        "name": "WarmupSet",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "OHM",
        "outputs":
          [
            {
              "internalType": "contract IERC20",
              "name": "",
              "type": "address"
            }
          ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "authority",
        "outputs":
          [
            {
              "internalType": "contract IOlympusAuthority",
              "name": "",
              "type": "address"
            }
          ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs":
          [
            {
              "internalType": "address",
              "name": "_to",
              "type": "address"
            },
            {
              "internalType": "bool",
              "name": "_rebasing",
              "type": "bool"
            }
          ],
        "name": "claim",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "distributor",
        "outputs":
          [
            {
              "internalType": "contract IDistributor",
              "name": "",
              "type": "address"
            }
          ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "epoch",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "length",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "number",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "end",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "distribute",
              "type": "uint256"
            }
          ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "forfeit",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "gOHM",
        "outputs":
          [
            {
              "internalType": "contract IgOHM",
              "name": "",
              "type": "address"
            }
          ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "index",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "rebase",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "sOHM",
        "outputs":
          [
            {
              "internalType": "contract IsOHM",
              "name": "",
              "type": "address"
            }
          ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "secondsToNextEpoch",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs":
          [
            {
              "internalType": "contract IOlympusAuthority",
              "name": "_newAuthority", "type": "address"
            }
          ],
        "name": "setAuthority",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs":
          [
            {
              "internalType": "address",
              "name": "_distributor",
              "type": "address"
            }
          ],
        "name": "setDistributor",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs":
          [
            {
              "internalType": "uint256",
              "name": "_warmupPeriod",
              "type": "uint256"
            }
          ],
        "name": "setWarmupLength",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs":
          [
            {
              "internalType": "address",
              "name": "_to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "_amount",
              "type": "uint256"
            },
            {
              "internalType": "bool",
              "name": "_rebasing",
              "type": "bool"
            },
            {
              "internalType": "bool",
              "name": "_claim",
              "type": "bool"
            }
          ],
        "name": "stake",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "supplyInWarmup",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "toggleLock",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs":
          [
            {
              "internalType": "address",
              "name": "_to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "_amount",
              "type": "uint256"
            },
            {
              "internalType": "bool",
              "name": "_trigger",
              "type": "bool"
            },
            {
              "internalType": "bool",
              "name": "_rebasing", "type": "bool"
            }
          ],
        "name": "unstake",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "amount_",
              "type": "uint256"
            }
          ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs":
          [
            {
              "internalType": "address",
              "name": "_to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "_amount",
              "type": "uint256"
            }
          ],
        "name": "unwrap",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "sBalance_",
              "type": "uint256"
            }
          ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs":
          [
            {
              "internalType": "address",
              "name": "", "type": "address"
            }
          ],
        "name": "warmupInfo",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "deposit",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "gons",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "expiry",
              "type": "uint256"
            },
            {
              "internalType": "bool",
              "name": "lock",
              "type": "bool"
            }
          ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "warmupPeriod",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs":
          [
            {
              "internalType": "address",
              "name": "_to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "_amount",
              "type": "uint256"
            }
          ],
        "name": "wrap",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "gBalance_",
              "type": "uint256"
            }
          ],
        "stateMutability": "nonpayable",
        "type": "function"
      }
    ]
  },
  sohmContract: {
    address: '0x291510B346435F22f19Dd901171931a5d5CD5B42',
    abi: [
      {
        "inputs": [],
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "Approval",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "uint256",
            "name": "epoch",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "rebase",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "index",
            "type": "uint256"
          }
        ],
        "name": "LogRebase",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "stakingContract",
            "type": "address"
          }
        ],
        "name": "LogStakingContractUpdated",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "uint256",
            "name": "epoch",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "totalSupply",
            "type": "uint256"
          }
        ],
        "name": "LogSupply",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "Transfer",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "DOMAIN_SEPARATOR",
        "outputs": [
          {
            "internalType": "bytes32",
            "name": "",
            "type": "bytes32"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner_",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          }
        ],
        "name": "allowance",
        "outputs":
          [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "approve",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "gons",
            "type": "uint256"
          }
        ],
        "name": "balanceForGons",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "who",
            "type": "address"
          }
        ],
        "name": "balanceOf",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "debtor",
            "type": "address"
          },
          {
            "internalType": "bool",
            "name": "add",
            "type": "bool"
          }
        ],
        "name": "changeDebt",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "circulatingSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "debtBalances",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "decimals",
        "outputs": [
          {
            "internalType": "uint8",
            "name": "", "type": "uint8"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "subtractedValue",
            "type": "uint256"
          }
        ],
        "name": "decreaseAllowance",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "fromG",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "gOHM",
        "outputs": [
          {
            "internalType": "contract IgOHM",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "gonsForBalance",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "addedValue",
            "type": "uint256"
          }
        ],
        "name": "increaseAllowance",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "index",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_stakingContract",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_treasury",
            "type": "address"
          }
        ],
        "name": "initialize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "name",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          }
        ],
        "name": "nonces",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "deadline",
            "type": "uint256"
          },
          {
            "internalType": "uint8",
            "name": "v",
            "type": "uint8"
          },
          {
            "internalType": "bytes32",
            "name": "r",
            "type": "bytes32"
          },
          {
            "internalType": "bytes32",
            "name": "s",
            "type": "bytes32"
          }
        ],
        "name": "permit",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "profit_",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "epoch_",
            "type": "uint256"
          }
        ],
        "name": "rebase",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "name": "rebases",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "epoch",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "rebase",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalStakedBefore",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalStakedAfter",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "amountRebased",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "index",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "blockNumberOccured",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_index",
            "type": "uint256"
          }
        ],
        "name": "setIndex",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_gOHM",
            "type": "address"
          }
        ],
        "name": "setgOHM",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "stakingContract",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "symbol",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "toG",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "transfer",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          }
        ],
        "name": "transferFrom",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "treasury",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }
    ]
  },
  treasury: {
    address: '0x7f2089a1bE5A7E25b9aA62f0de6c60132D440304'
  }
}
