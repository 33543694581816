export const setAccountId = account => {
    return {
        type: 'SET_ACCOUNTID',
        payload: account
    }
};

export const setExtendNav = extendNav => {
    return {
        type: 'SET_EXTENDNAV',
        payload: extendNav
    }
};
