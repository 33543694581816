const initialState = {
    extendNav: false
};

const appReducer = (state = initialState, action) =>{
    switch (action.type) {
        case 'SET_EXTENDNAV' : 
            return {...state, extendNav : action.payload}
        
        default:
            return state;
    }
}

export default appReducer;