import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import styled from "styled-components";
import GradientButton from "./components/GradientButton";
import { getAccountDetails } from "./components/blockchain/commonFunction";
import { setAccountId } from "./redux/action";
import { useNavigate } from "react-router-dom";


export const MetaLoging = () =>{
    const dispatch = useDispatch()
    const navigate = useNavigate();



    const connectWallet = async () => {
        try {
          const { ethereum } = window;
          if (ethereum) {
            let account = await getAccountDetails()
            if(account){
              dispatch(setAccountId(account.account))
              window.ethereum.on("chainChanged", () => {
                window.location.reload();
              });
      
              window.ethereum.on("accountsChanged", () => {
                window.location.reload();
              });
              navigate('/dashboard')
            }
         
          }
        }
        catch (error) {
          console.log(error);
        }
      }

    return(
        <LoginContainer style={{background:'blue'}}>
        <ButtonContainer>
           <button onClick={async() => await connectWallet()} style={{height:'80px', width:'180px', fontWeight:'bold'}}>
                Login
           </button>
          </ButtonContainer>
        </LoginContainer>
    )
}

export const ButtonContainer = styled.div`
  @media (max-width: 920px) {
    margin-top: 20px;
  }
`;
export const LoginContainer = styled.div`
z-index: 1;
align-self: center;
margin-left: auto;
margin-right: auto;
margin-top: 50vh;
`;