import { ethers } from 'ethers';
import { provider, contractDetails } from './contractDetails'

const getMetaMask = () => {
    const providerMetaMask = new ethers.providers.Web3Provider(window.ethereum)
    return providerMetaMask
}


const getAccountDetails = async () => {
    try {
        const details = {};
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        const networkName = await provider.getNetwork();
        const chainId = await window.ethereum.request({ method: 'net_version' });
        const balance = await provider.getBalance(accounts[0]);

        details.account = accounts[0];
        details.networkName = networkName.name;
        details.chainId = chainId;
        details.balance = ethers.utils.formatEther(balance, 18)
        if (accounts.length) {
            return details
        } else {
            console.log("ERROR IN  METAMASK CONNECTION")
        }
    } catch (error) {
       
        return false
    }

}

const getAddress = async () => {
        await getAccountDetails().then((res) =>{
            return res.account
        }).catch(err => {
            console.log(err)
        })
  
}

const getChainId = async () => {
    try {
        const network = await getAccountDetails()
        return network.chainId
    } catch (error) {
        console.log(error)
    }

}

const writeFunction = async (token) => {
    const chainId = await getChainId()
    const provider = getMetaMask()
    const signer = await provider.getSigner()
    return new ethers.Contract(
        contractDetails[token].address[chainId],
        contractDetails[token].abi,
        signer
    )
}


const OhmContract = async (token) => {
    const OHMContract = new ethers.Contract(contractDetails[token].address,
        contractDetails[token].abi, provider);
    return OHMContract
}

const daiContract = async (token) => {
    const OHMContract = new ethers.Contract(contractDetails[token].address,
        contractDetails[token].abi, provider);
    return OHMContract
}

const ReadSmartContract = async () => {
    const OHMContract = await OhmContract('ohm')
    const totalSupply = await OHMContract.totalSupply();
    const SupplyInEther = ethers.utils.formatUnits(totalSupply, 9);
}

const getTotalSupply = async () => {
    const contract = await OhmContract('ohm')
    const totalSupplys = await contract.totalSupply();
    const num = parseInt(totalSupplys, 16)
    return num
}

const circulatingSupply = async () => {
    const contract = await OhmContract('ohm')
    const totalSupplys = await contract.totalSupply();
    const ts = ethers.utils.formatUnits(totalSupplys, 9)
    const balance = await contract.balanceOf(contractDetails['treasury'].address)
    const bal = ethers.utils.formatUnits(balance, 9)
    const total = Number(ts - bal)
    return total.toFixed(2)
}

const getOhmTotalSupply = async () => {
    const contract = await OhmContract('ohm')
    const totalSupplys = await contract.totalSupply();
    const num = ethers.utils.formatUnits(totalSupplys, 9)
    return Number(num).toFixed(2)
}

const getLiquidBacking = async () => {
    const contract = await daiContract('daiContract')
    const ts = await contract.totalSupply()
    const Supply = ethers.utils.formatUnits(ts, 18)
    const balance = await contract.balanceOf(contractDetails['treasury'].address)
    const conv = ethers.utils.formatUnits(balance, 18)
    const bal = Number(Supply) / Number(conv)
    return bal.toFixed(2)
}

const getLiveMarket = async () => {
    const provider = getMetaMask()
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractDetails['bondDepository'].address,
        contractDetails['bondDepository'].abi,
        signer);
    const getLiveMarket = await contract.liveMarkets()
    const getValu = await handleMarketValue(getLiveMarket, contract)
    return getValu
}


const handleMarketValue = async (val, contract) => {
    let arr = []
    for (let e in val) {
        const price = await contract.marketPrice(Number(val[e]))
        const getPayOut = await contract.markets(Number(val[e]))
        const getDuration = await contract.terms(Number(val[e]))
        arr.push({ 'MarketId': val[e], 'Price': price._hex, 'MaxPay': getPayOut.maxPayout._hex, 'Duration': getDuration.conclusion, "marketPrice": price })
    }
    return arr
}

const SubmitBondDeposit = async (val, Price, Id) => {
    const provider = getMetaMask()
    const signer = provider.getSigner();
    const dai = new ethers.Contract(contractDetails['daiContract'].address,
        contractDetails['daiContract'].abi,
        signer);
    const unitChange = await ethers.utils.parseUnits(val, 18);
    await dai.approve(contractDetails['bondDepository'].address, unitChange).
        then(async (res) => {
            if (res) {
                approveDai(res, Price, Id, unitChange)
            }
        }).catch((e) => {
            console.log(e)
        })
}

const approveDai = async (res, Price, Id, unitChange) => {
    const provider = getMetaMask()
    const add = await getAddress()
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractDetails['bondDepository'].address,
        contractDetails['bondDepository'].abi,
        signer);
    await contract.deposit(Id, unitChange, Price, add, add).then((res) => {
        if (res) {
            window.location.reload()
        }
    }).catch(e => {
        console.log(e)
    })
}

const approveStake = async (val) => {
    const providers = new ethers.providers.Web3Provider(window.ethereum, "any");
    const provider = getMetaMask()
    const userInput = ethers.utils.parseUnits(val, 9)
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractDetails['ohm'].address,
        contractDetails['ohm'].abi,
        signer);
    const approve = await contract.approve(contractDetails['stakeContract'].address, userInput).then(async (res) => {
        if (res.gasLimit) {
            const checkTrans = await provider.waitForTransaction(res.hash)
            if (checkTrans) {
                submitStaking(val)
            }
        }
    }).catch(e => {
        console.log(e)
    })

}

const submitStaking = async (val) => {
    const add = await getAddress();
    const provider = getMetaMask()
    const signer = provider.getSigner();
    const userInput = await ethers.utils.parseUnits(val, 9)
    const contract = new ethers.Contract(contractDetails['stakeContract'].address,
        contractDetails['stakeContract'].abi,
        signer);
    const approve = await contract.stake(add, userInput, true, true).then((res) => {
    }).catch(e => {
        console.log(e)
    })
    return approve
}

const getIndexValue = async () => {
    const provider = getMetaMask()
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractDetails['sohmContract'].address,
        contractDetails['sohmContract'].abi,
        signer);
    const indexValue = await contract.index()
    const val = Number(indexValue)
    const ConvertedVal = ethers.utils.formatUnits(val, 9)
    return ConvertedVal
}


export {
    getAccountDetails,
    ReadSmartContract,
    getTotalSupply,
    getLiveMarket,
    handleMarketValue,
    SubmitBondDeposit,
    approveStake,
    submitStaking,
    getIndexValue,
    getLiquidBacking,
    circulatingSupply,
    getOhmTotalSupply,
}