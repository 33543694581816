import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'

import './appHeader.css'
import { setExtendNav } from '../../redux/action'

export const AppHeader = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state => state))

    return (
        <>
            <div className="Header">
                <h1 className="OpenLinkButton"
                    onClick={() => dispatch(setExtendNav(!selector.menuFrameReducer.extendNav))}>
                    {selector.menuFrameReducer.extendNav ? <>&#10005;</> : <>&#8801;</>}
                </h1>
            </div>
        </>
    )
}
