import React, { useState } from "react";
import styled from "styled-components";
import Question from "./Question";

const qas = [
  {
    i: "q1",
    q: "What is StableDao?",
    a: "Stabledao Protocol, governed by the Stabledao DAO, is Web3's decentralised reserve currency. SDAO serves as the censorship-resistant digital currency of choice, catering to the needs of the growing Web3 financial ecosystem. With a focus on preserving purchasing power, deep liquidity, and widespread adoption as a trusted unit of account, Stabledao Protocol empowers users to transact with confidence. Backed by a robust and expanding Treasury, Stabledao Protocol acts as a stabilizing force within the volatile market, ensuring a resilient and stable ecosystem. Join Stabledao Protocol today and experience the future of decentralised reserve currencies.",
  },
  {
    i: "q2",
    q: "What is the goal of Stabledao?",
    a: `Stabledao is an innovative project aiming to establish a policy-controlled financial reserve currency that excels in various aspects: Ensuring long-term price stability to preserve purchasing power
    Facilitating deep liquidity on both decentralized and centralized exchanges
    Serving as a widely accepted unit of account within the Web3 ecosystem, with numerous asset pairings
    Acting as a trusted backing asset, collateralizing other assets or enhancing the treasuries of different protocols. With Stabledao's dedication to these principles, we strive to redefine the landscape of decentralized finance. Join Stabledao today and be a part of the future of resilient and efficient financial systems.`,
  },
  {
    i: "q3",
    q: "What is the native token of Stabledao?",
    a: "The native token of Stabledao is called SDAO. It serves as a governance token, enabling holders to participate in the decision-making process of the protocol.",
  },
  {
    i: "q4",
    q: "How does Stabledao ensure price stability?",
    a: "Stabledao implements a backing mechanism to maintain price stability. Each SDAO token is backed by a specific value of assets held in the protocol's treasury. This backing ensures that the value of SDAO remains stable and resilient.",
  },
  {
    i: "q5",
    q: "Can I earn rewards by staking SDAO?",
    a: "Yes, Stabledao offers staking rewards to SDAO holders. By staking your SDAO tokens, you can earn additional SDAO as a reward for actively participating in the protocol.",
  },
  {
    i: "q6",
    q: "How can I participate in the governance of Stabledao?",
    a: "o participate in the governance of Stabledao, you need to hold gDAO tokens. gDAO holders can vote on proposals, contribute to decision-making, and shape the future development of the protocol.",
  },
  {
    i: "q7",
    q: "Is Stabledao transparent and decentralized?",
    a: "Yes, Stabledao operates with full transparency and is governed by a decentralized autonomous organization (DAO). The DAO ensures that the decision-making process is community-driven and transparent.",
  },
  {
    i: "q8",
    q: "How can I get started with Stabledao?",
    a: "To get started with Stabledao, you can acquire SDAO tokens through participating in token sales, or trading on supported exchanges. Once you hold SDAO, you can explore various features of the protocol, including staking and governance participation.",
  },
];

const Questions = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <Panel>
      <Title>Frequently Asked Questions</Title>
      <Content>
        {qas.map((qa, index) => {
          return (
            <Question
              key={qa.i}
              open={selectedIndex === index}
              onClick={() => {
                if (selectedIndex === index) {
                  setSelectedIndex(-1);
                } else {
                  setSelectedIndex(index);
                }
              }}
              question={qa.q}
              answer={qa.a}
            />
          );
        })}
      </Content>
    </Panel>
  );
};

export default Questions;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;
const Title = styled.div`
  color: #beffba;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
`;
const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  margin-top: 70px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;
