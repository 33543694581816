import moment from 'moment'
import { ethers } from 'ethers';

export const truncateToDecimals = (num, dec = 10) => {
	const calcDec = Math.pow(10, dec)
	
	return String(Math.trunc(num * calcDec) / calcDec)
}

export const convertToTime = (time) => {
    const res = parseInt(time, 16) * 1000
    return moment(res).format('hh:mm:ss')
}

export const fonrmatEather = (val) =>{
    const price = ethers.utils.formatUnits(val, 9)
    const value = price /100
    return value
  }