import React from "react";
import styled from "styled-components";
import Item from "./Item";

const details = [
  {
    id: 'one1',
    label: 'Introducing Stabledao',
    desc: `The Future of Decentralized Reserve Currency`,
    date: 'June 5',
    time: 9
  },
  {
    id: 'two2',
    label: 'Stabledao',
    desc: 'The Ultimate Solution to Cryptocurrency Volatility',
    date: 'June 5',
    time: 9
  },
  {
    id: 'three3',
    label: `Say Goodbye to Traditional Banking with Stabledao's Decentralized Protocol`,
    desc: 'The Ultimate Solution to Cryptocurrency Volatility',
    date: 'June 5',
    time: 9
  },
  {
    id: 'four4',
    label: `How Stabledao's Decentralization is Revolutionizing the Crypto Market`,
    desc: `The Ultimate Solution to Cryptocurrency Volatility`,
    date: 'June 5',
    time: 9
  },
]

const News = () => {
  return (
    <Panel>
      <Title>What's New</Title>
      <Content>
        {details.map(item => (
          <>
            <Item value={item} />
            <Space />
          </>
        ))}
      </Content>
    </Panel>
  );
};

export default News;

const Space = styled.div`
  width: 30px;
  @media (max-width: 768px) {
    height: 15px;
  }
`;
const Content = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const Title = styled.div`
  color: #beefba;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 35px;
`;
const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 80px;
`;
