import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { getAccountDetails } from "../../../../components/blockchain/commonFunction";
import { setAccountId, setExtendNav } from "../../../../redux/action";
import styled from "styled-components";
import metamask from '../../../../assets/metamask.svg';
import logo from '../../../../images/small_logo.svg'


const Wrap = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state => state));
  const navigate = useNavigate()

  useEffect(() => {
      (async () => {
        try {
          await connectWallet()
        } catch (err) {
          console.log(err)
        }
      })()
    dispatch(setExtendNav(true))
  }, [selector.appReducer.account_id])


  const connectWallet = async () => {
    try {
      const { ethereum } = window;
      if (ethereum) {
        let account = await getAccountDetails();
        if (account) {
          dispatch(setAccountId(account.account))
          navigate('/dashboard')
          window.ethereum.on("chainChanged", () => {
            window.location.reload();
          });

          window.ethereum.on("accountsChanged", () => {
            window.location.reload();
          });
        }
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <Container >
      <ButtonBox>
        <Logo onClick={() => navigate('/')}>
        <LogoImage src={logo} alt="Your SVG"/>
        <LogoTitle>StableDAO</LogoTitle>
        </Logo>
        <ImageSection>
          <img src={metamask} alt="" width="250" />
        </ImageSection>
        <TextDiv>
          Please log in to Metamask first. If not installed,
          please follow below link to install it.<br />
          <a target="_blank" href="https://metamask.io/download/">https://metamask.io/download</a>
        </TextDiv>
      </ButtonBox>
    </Container>
  )
}

export default Wrap

const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgb(30, 30, 30)
`;

const ButtonBox = styled.div`
background-color: rgb(50, 55, 62);
height: 600px;
width: 44%;
padding: 44px;
`

const ImageSection = styled.div`
display: flex;
justify-content: center;
padding: 18px 0px 30px 0px;
`

const TextDiv = styled.div`
text-align: center;
color: white;
font-size: 22px;
`

const Logo = styled.div`
  flex: 1;
  display: flex;
  font-weight: 700;
  font-size: 24px;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
  justify-content: center;
`;

export const LogoTitle = styled.div`
  font-size: 24px;
  color: white;
`;
export const LogoImage = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
`;
