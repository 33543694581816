import React, { useState } from "react";
import {
  Container,
  Header,
  HeaderItem,
  RightPart,
  Logo,
  LogoImage,
  LogoTitle,
  OpenLinkButton,
} from "./styles";
import SmallLogo from "../../images/small_logo.svg";
import GradientButton from "../../components/GradientButton";
import Footer from "./components/Footer";
import { ButtonContainer } from "./styles";
import { useNavigate } from "react-router-dom";
import { Dropdwon } from '../MainView/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { setExtendNav } from '../../redux/action';


export const menus = [
  { id: 'm1', label: "Stake", path: '/stake' },
  { id: 'm2', label: "Bond", path: '/bond' },
  { id: 'm3', label: "StableDAO Pro", path: '/comingsoon' },
  { id: 'm4', label: "Blog", path: '/' },
  { id: 'm5', label: "Transparency", path: '/' },
  { id: 'm6', label: "Governance", path: '/' },
];
const MainView = ({ children, onSelectIndex }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [extendNavi, setExtendNavi] = useState(false);
  const selector = useSelector((state => state))
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <Container>
      <Header>
        <Logo onClick={() => navigate('/')}>
          <LogoImage src={SmallLogo} alt="Your SVG" />
          <LogoTitle>StableDAO</LogoTitle>
        </Logo>
        <RightPart>
          {menus.map((menu, index) => {
            return (
              <HeaderItem
                key={index}
                selected={index === selectedIndex}
                onClick={() => {
                  setSelectedIndex(index);
                  navigate(menu.path)
                  if (onSelectIndex) {
                    onSelectIndex(index);
                  }
                }}
              >
                {menu.label}
              </HeaderItem>
            );
          })}
          <OpenLinkButton onClick={() => setExtendNavi(!extendNavi)}>{extendNavi ? <>&#10005;</> : <>&#8801;</>}</OpenLinkButton>
          <ButtonContainer>
            <GradientButton
              label={"Launch App"}
              onClick={() => {
                selector.appReducer.account_id === "" ? navigate("/wrap") 
                : 
                navigate("/dashboard")
              }}
            />
          </ButtonContainer>
        </RightPart>
        <Dropdwon extendNavi={extendNavi} setExtendNavi={setExtendNavi} onClickOutside={() => { setExtendNavi(false) }} />
      </Header>
      {children}
      <Footer />
    </Container>
  );
};

export default MainView;
