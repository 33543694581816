import React, { useRef, useEffect } from "react";
import { menus } from '../MainView/MainView';
import '../MainView/Dropdown.css';
import { useNavigate } from "react-router-dom";

export const Dropdwon = ({ extendNavi, setExtendNavi, onClickOutside }) => {
  const navigate = useNavigate();
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <div ref={ref} className={extendNavi ? 'dropmenu' : 'dropmenu clicked'} >
      <div className="closeButton">
        <div onClick={() => setExtendNavi(!extendNavi)} className="OpenLinkButton" >{<>&#10005;</>}</div>
      </div>
      {menus.map(res => (
        <div className="li" key={res.id}>
          <div onClick={() => navigate(res.path)}>
            {res.label}
          </div>
        </div>
      ))}
    </div>
  )
}
