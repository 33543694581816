import React from "react";
import {
  Content,
  LeftPanel,
  RightImage,
  TopImage,
  BottomImage,
  Description,
  LeftArrow,
  BottomPanel,
  RightPanel,
  ActionPanel,
  ButtonContainer,
  ReadDoc,
  LabelText,
  LogoImage,
  LargeLeftTitle,
  SmallLeftTitle,
  ArrowContainer,
  Spacer,
} from "./styles";

import BottomMark from "../../../../../../images/bottom_mark.svg";
import TopMark from "../../../../../../images/top_mark.svg";
import ReadIcon from "../../../../../../images/read.svg";
import LeftArrowImage from "../../../../../../images/left_arrow.svg";
import GradientButton from "../../../../../../components/GradientButton";
import { useNavigate } from "react-router-dom";


const LaunchPad = () => {
  const navigate = useNavigate();

  return (
    <Content>
      <LeftPanel>
        <LargeLeftTitle>
          The Decentralizeds <br /> Reserve Currency of <br />
          the Future
        </LargeLeftTitle>
        <SmallLeftTitle>
          The Decentralized Reserve Currency of the Future
        </SmallLeftTitle>
        <BottomPanel>
          <ArrowContainer>
            <Spacer />
            <LeftArrow src={LeftArrowImage} />
          </ArrowContainer>
          <RightPanel>
            <Description>
              Stabledao is revolutionizing the world of decentralized finance (DeFi) with SDAO,
              a community-owned, censorship-resistant, and asset-backed reserve currency.
              As a trusted and deeply liquid digital asset, SDAO is gaining widespread adoption
              within the Web3 ecosystem. Stabledao's commitment to decentralization ensures that SDAO
              remains resilient and responsive to the evolving needs of its users. Experience the power of
              SDAO and join the Stabledao community in shaping the future of decentralized finance.
            </Description>
            <ActionPanel>
              <ButtonContainer>
                <GradientButton onClick={() => navigate("/dashboard")} label="Launch App" />
              </ButtonContainer>
              <ReadDoc onClick={() => window.open('https://docs.stabledao.io/')}>
                <LogoImage src={ReadIcon} />
                <LabelText>Read Docs</LabelText>
              </ReadDoc>

            </ActionPanel>
          </RightPanel>
        </BottomPanel>
      </LeftPanel>
      <RightImage>
        <TopImage src={TopMark} />
        <BottomImage src={BottomMark} />
      </RightImage>
    </Content>
  );
};

export default LaunchPad;
