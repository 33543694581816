import styled from "styled-components";

export const PageContent = styled.div`
  padding: 0 50px;
  @media (max-width: 580px) {
    padding: 0 20px;
  }
`;
export const Container = styled.div`
  background-color: #1e1e1e;
`;
