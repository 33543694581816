import React from "react";
import styled from "styled-components";
import StatInfo from "./StatInfo";
const Stats = () => {

  const desc = [
    {
      value: '241924660',
      des: 'Number of Holders'
    },
    {
      value: '241924660',
      des: 'OHM Votes (OIP-94)'
    },
    {
      value: '241924660',
      des: 'Participants (OIP-94)'
    },
  ]

  return (
    <Panel>
      <Title>Protocol Stats</Title>
      <Content>
        {desc.map((item, index) => (
          <StatInfo
            key={index}
            value={item} />
        ))}
        {/* <StatInfo value={241924660} />
        <StatInfo value={69359977} />
        <StatInfo value={50} /> */}
      </Content>
    </Panel>
  );
};

export default Stats;

const Content = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const Title = styled.div`
  color: #beffba;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 20px;
`;
const Panel = styled.div`
  background-color: #1b1f24;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  margin-top: 100px;
`;
