import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Title } from "../viewStyles";
import StatInfo from "../../components/StatInfo";
import GeneralButton from "../../components/GeneralButton";
import { BiWallet } from "react-icons/bi";
import TokenTable from "../../components/TokenTable";
import { getLiveMarket } from '../../components/blockchain/commonFunction'
import { setExtendNav } from '../../redux/action'

const Dashboard = ({ }) => {
  const [marketDetails, setMarketDetails] = useState([])
  const [openModal, setOpenModal] = useState(false);
  const selector = useSelector((state => state))
  const navigate = useNavigate();
  const dispatch = useDispatch();



  useEffect(() => {
    if (selector.appReducer.account_id === "" || selector.appReducer.account_id === undefined) {
      (async function () {
        try {
          const getMarket = await getLiveMarket();
          setMarketDetails(getMarket);
        } catch (err) {
          console.log(err)
        }
      })();
    }
    dispatch(setExtendNav(false))
  }, []);

  useEffect(() => {
    if (selector.appReducer.account_id === "") {
      navigate('/wrap')
    }
    return () => {
    }
  }, [])

  return (
    <Container>
      <Content>
        <TopLine>
          <Title
            onClick={() => {
              navigate("/");
            }}
          >
            Bond
          </Title>
          <ButtonContainer>
            <GeneralButton
              bgColor="#32373e"
              labelColor="white"
              label={selector.appReducer.account_id === "" ? 'Connect Wallet' : 'Connected'}
              icon={<BiWallet size={20} color={"white"} />}
            />
          </ButtonContainer>
        </TopLine>
        <Panel>
          <StatInfo value={241924660} label="Treasure Balance" />
          <StatInfo value={120000} label="SDAP Price" />
        </Panel>
        <TokenTable
          marketDetails={marketDetails}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      </Content>
    </Container>
  );
};

export default Dashboard;

const ButtonContainer = styled.div`
  width: 140px;
  
`;
const TopLine = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 60px;
  justify-content: space-between;
`;
const Container = styled.div`
  background-color: #1e1e1e;
  flex: 1;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;
const Content = styled.div`
  margin-top: 50px;
  display: flex;
  width: 90%;
  flex-direction: column;
`;

const Panel = styled.div`
  background: linear-gradient(#1e1e1e, #1e1e1e) padding-box,
    linear-gradient(to right, #beffba, #ffffff) border-box;
  border: 3px solid transparent;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  @media (max-width: 568px) {
    flex-direction: column;
  }
`;
