import React, { useState } from "react";
import {useSelector } from 'react-redux'
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import SmallLogo from "../../images/small_logo.svg";
import DashIcon from "../../images/menuIcons/dash.svg";
import DisableDashIcon from "../../images/menuIcons/disable_dash.svg";
import DisableBondIcon from "../../images/menuIcons/bond.svg";
import ActiveBondIcon from "../../images/menuIcons/active_bond.svg";
import DisableStakeIcon from "../../images/menuIcons/stake.svg";
import ActiveStakeIcon from "../../images/menuIcons/active_stake.svg";
import './MenuFrame.css'

const MenuFrame = ({ }) => {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(1);
  const location = useLocation();
  const selector = useSelector((state => state))

  const handleFilter = (val) => {
    if (val === 1) {
      navigate('/dashboard');
      setSelectedIndex(1);
      return null
    }
    if (val === 2) {
      navigate('/bond');
      setSelectedIndex(2);
      return null
    }
    if (val === 3) {
      navigate('/stake');
      setSelectedIndex(3);
      return null
    }
  }

  return (
    <div className={selector.menuFrameReducer.extendNav ? "MenuShowHide open" : "MenuShowHide"} isDashboard={location.pathname === "/"}>
      <Logo
        onClick={() => {
          navigate("/");
        }}
      >
        <LogoImage src={SmallLogo} alt="Your SVG" />
        <LogoTitle>StableDAO</LogoTitle>
      </Logo>
      <MenuItem
        onClick={() => handleFilter(1)}
      >
        <Icon src={selectedIndex === 1 ? DashIcon : DisableDashIcon} />
        <ItemText>Dashboard</ItemText>
      </MenuItem>
      <hr style={{ color: '#ffffff', height: '2px', border: '0px solid', background: '#fffff' }} />
      <MenuItem
        onClick={() => handleFilter(2)}
      >
        <Icon src={selectedIndex === 2 ? ActiveBondIcon : DisableBondIcon} />
        <ItemText>Bond</ItemText>
      </MenuItem>
      <MenuItem
        onClick={() => handleFilter(3)}
      >
        <Icon src={selectedIndex === 3 ? ActiveStakeIcon : DisableStakeIcon} />
        <ItemText>Stake</ItemText>
      </MenuItem>
    </div>
  );
};

export default MenuFrame;

const ItemText = styled.div`
  font-size: 20px;
  color: white;
  margin-left: 15px;
`;
const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-left: 10px;

`;
const MenuItem = styled.div`
  display: flex;
  padding: 15px 10px;
  cursor: pointer;
`;
const Menu = styled.div`
  width: 300px;
  background-color: #121418;
  padding-top: 30px;
  // padding-left: 20px;
  display: ${({ isDashboard }) => (isDashboard ? "none" : "block")};
  @media (max-width: 1080px) {
    display: block;
  }
`;

export const LogoTitle = styled.div`
  font-size: 24px;
  color: white;
`;
export const LogoImage = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 15px;
`;
export const Logo = styled.div`
  flex: 1;
  display: flex;
  font-weight: 700;
  font-size: 24px;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
  justify-content: center;
`;
