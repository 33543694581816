const initialState = {
    account_id:""
};

const appReducer = (state = initialState, action) =>{
    switch (action.type) {
        case 'SET_ACCOUNTID' : 
            return {...state, account_id : action.payload}
        
        default:
            return state;
    }
}

export default appReducer;