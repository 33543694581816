import {createStore} from "redux"
import appReducer from './reducer'
import menuFrameReducer from './menuFrameReducer'
import {combineReducers} from 'redux'

const reducer = combineReducers({
    appReducer: appReducer,
    menuFrameReducer: menuFrameReducer
})

const store = createStore(reducer);

export default store;