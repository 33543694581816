import React from "react";
import "./App.css";
import LandingView from "./view/LandingView";
import Dashboard from "./view/Dashboard";
import Bond from "./view/Bond";
import { MetaLoging } from "./metaLogin";
import Stake from "./view/Stake";
import styled from "styled-components";
import MenuFrame from "./view/MenuFrame";
import AppHeader from "./view/AppHeader";
import { StablePro } from "./view/LandingView/stablePro/StablePro";
import { Routes, Route, HashRouter } from "react-router-dom";
import Wrap from "./view/LandingView/components/wrap/wrap";

function App() {

  return (
    <>
      <HashRouter hasType="hashbang">
        <React.StrictMode>
          {/* <BrowserRouter> */}
          <Routes>
            <Route path="/" element={<LandingView />} />
            <Route path="/comingsoon" element={<StablePro />} />
            <Route path="/meta" element={<MetaLoging />} />
            <Route path="/wrap" element={<Wrap />} />
          </Routes>
          <Container>
            <MenuFrame />
            <AppHeader />
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/bond" element={<Bond />} />
              {/* <Route path="/range" element={<Range />} /> */}
              <Route path="/stake" element={<Stake />} />
            </Routes>
          </Container>
          {/* </BrowserRouter> */}
        </React.StrictMode>
      </HashRouter>
    </>
  );
}

export default App;

const Container = styled.div`
  display: flex;
  flex: 1;
  // min-height: 100vh;
`;
