import { Container, PageContent } from "./styles";
import LaunchPad from "./components/LaunchPad";
import Stats from "./components/Stats";
import Participation from "./components/Participation";
import News from "./components/News";
import Questions from "./components/Questions";
import { useDispatch, useSelector } from 'react-redux'
import { setExtendNav } from '../../../../redux/action'
import { useEffect } from "react";

const Landing = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state => state))

  useEffect(() => {
    dispatch(setExtendNav(true))
  }, [selector.menuFrameReducer.extendNav])
  return (
    <Container>
      <PageContent>
        <LaunchPad />
        <Stats />
        <Participation />
        <News />
        <Questions />
      </PageContent>
    </Container>
  );
};

export default Landing;
