import React from "react";
import styled from "styled-components";
import Item from "./Item";

const desc= {
  bond: `Bonding on the Stabledao platform offers you the opportunity to exchange different tokens for STBL at discounted prices. This mutually beneficial process enhances liquidity and bolsters the reserves of the Stabledao treasury, thereby reinforcing the protocol's stability. With Stabledao's commitment to`,
  stake: `Stakers are vital participants within the Stabledao ecosystem. By depositing their STBL tokens into the protocol, stakers actively contribute to the long-term price stability of STBL. In return, stakers receive proportional rebases and gain governance rights within the Stabledao community. Discover the rewarding benefits of staking your STBL and become an integral part of the Stabledao network.`
}

const Participation = () => {
  return (
    <Panel>
      <Title>How to Participate</Title>
      <Content>
        <Item label="Staking" desc={desc.stake} />
        <Space />
        <Item label="Bonding"  desc={desc.bond}/>
      </Content>
    </Panel>
  );
};

export default Participation;

const Space = styled.div`
  width: 30px;
  @media (max-width: 768px) {
    height: 20px;
  }
`;
const Content = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const Title = styled.div`
  color: white;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 35px;
`;
const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 80px;
  padding: 30px
`;
