import {
  Content,
  LeftPanel,
  Description,
  BottomPanel,
  RightPanel,
  LargeLeftTitle,
} from "./styles";

const LaunchPad = () => {
  return (
    <Content>
      <LeftPanel>
        <LargeLeftTitle>TRANSPARENCY</LargeLeftTitle>
        <BottomPanel>
          <RightPanel>
            <Description>
            Stabledao is a community-owned protocol, ensuring transparency in its operations.
             Dive into Stabledao's processes, performance, and other details to gain a comprehensive 
             understanding of how the protocol operates. Discover valuable insights about Stabledao's 
             functionalities, including the role of gSDAO (Stabledao's Governance Token) and the active 
             participation of the DAO community. Stay informed and explore the inner workings of Stabledao 
             for a transparent and rewarding DeFi experience. 
            </Description>
          </RightPanel>
        </BottomPanel>
      </LeftPanel>
    </Content>
  );
};

export default LaunchPad;
